export const GET_ALL_EXTRAFUNDS = 'EXTRAFUNDS/GET_ALL'
export const GET_ALL_EXTRAFUNDS_SUCCESS = 'EXTRAFUNDS/GET_ALL_SUCCESS'
export const GET_ALL_EXTRAFUNDS_FAILURE = 'EXTRAFUNDS/GET_ALL_FAILURE'
export const SUBMIT_NEW_EXTRAFUND_FORM = 'EXTRAFUNDS/SUBMIT_NEW_EXTRAFUND_FORM'
export const SUBMIT_NEW_EXTRAFUND_FORM_SUCCESS =
  'EXTRAFUNDS/SUBMIT_NEW_EXTRAFUND_FORM_SUCCESS'
export const SUBMIT_NEW_EXTRAFUND_FORM_FAILURE =
  'EXTRAFUNDS/SUBMIT_NEW_EXTRAFUND_FORM_FAILURE'
export const SUBMIT_EDIT_EXTRAFUND_FORM = 'EXTRAFUNDS/SUBMIT_EDIT_EXTRAFUND_FORM'
export const SUBMIT_EDIT_EXTRAFUND_FORM_SUCCESS =
  'EXTRAFUNDS/SUBMIT_EDIT_EXTRAFUND_FORM_SUCCESS'
export const SUBMIT_EDIT_EXTRAFUND_FORM_FAILURE =
  'EXTRAFUNDS/SUBMIT_EDIT_EXTRAFUND_FORM_FAILURE'

export const fetchExtraFunds = () => {
  return {
    type: GET_ALL_EXTRAFUNDS,
  }
}

export const submitNewExtraFundForm = (formValues) => {
  return {
    type: SUBMIT_NEW_EXTRAFUND_FORM,
    extraFund: formValues,
  }
}

export const submitEditExtraFundForm = (formValues) => {
  return {
    type: SUBMIT_EDIT_EXTRAFUND_FORM,
    extraFund: formValues,
  }
}
